import Vue from "vue";
import Vuex from "vuex";
import axios from "../common/axiosHttp";
import {Message} from "element-ui";
import router from "../router/index";
import {
	getAllSiteList,
} from '../api/index'

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		navItems: [],
		wsSiteData: null,
		wsGateData: null,
		wsRainData: null,
		wslRegimeDto: null
	},
	mutations: {
		// 设置 WS 推送数据
		setWSData(state, data) {
			if (data.gate) {
				state.wsGateData = data.gate;
			}
			if (data.site) {
				state.wsSiteData = data.site;
			}
			if (data.normalRainCount) {
				state.wsRainData = data;
			}
			if (data.rainfallRegimeDto) {
				state.wslRegimeDto = data.rainfallRegimeDto;
			}
		},
		// 获取菜单列表
		async getNavItems(state) {
			try {
				const menuList = await axios.get(
					`./menu/findRole/${localStorage.getItem("id")}`
				); // 获取菜单列表
				state.navItems = [...menuList.data];
				// console.log(state.navItems)
				let ulList
				const site = await axios.get("./site/findList"); // 获取站点列表
				const bz = await axios.get("./pumpStation/getPumpStations"); // 获取泵站列表
				const ul = await axios.get("./site/getAllSite"); // 获取定量用水
				ulList = ul.data.ditchFlowMeters.concat(ul.data.flowMeters)
				// console.log(ulList);
				const siteList = []; // 站点管理子菜单
				const useWaterList = []; // 定量用水子菜单
				const waterList = []; // 水位检测子菜单
				const videoAllList = []; // 监控总览子菜单
				const bzAllList = [] // 泵站子菜单

				for (const i of bz.data) {
					bzAllList.push({
						moduleName: i.staName,
						routeName: `/main/pumpStation/${i.id}/1-1`,
					});
				}
				// for(const i of ulList){
				//   useWaterList.push({
				//     moduleName: i.meterName||i.name,
				//     routeName: `/simpleness?lat=${i.lat}&lon=${i.lon}`,
				//     list:i,
				//   });
				// // }
				// console.log(site);
				for (const i of site.data) {
					siteList.push({
						moduleName: i.siteName,
						routeName: `/main/StationManage/${i.id}`,
					});

					waterList.push({
						moduleName: i.siteName,
						routeName: `/main/WaterLevel/${i.id}`,
					});
					videoAllList.push({
						moduleName: i.siteName,
						routeName: `/main/VideoAll/${i.id}`,
					});
				}
				// 插入动态子菜单
				for (let i = 0; i < state.navItems.length; i += 1) {
					const moduleName = state.navItems[i].moduleName;
					if (moduleName === "拦河闸管理") {
						state.navItems[i].children = [...siteList];
					} else if (moduleName === "泵站管理") {
						state.navItems[i].children = [...bzAllList];
					} else if (moduleName === "水位监测") {
						state.navItems[i].children = [...waterList];
					} else if (moduleName === "测流点管理") {
						// state.navItems[i].children = [...useWaterList];
					} else if (moduleName === "监控总览") {
						state.navItems[i].children = [...videoAllList];
						if (state.navItems[i].children.length !== 0) {
							state.navItems[i].children.unshift({
								moduleName: "全部站点",
								routeName: "/main/VideoAll/all",
							});
						}
					}
				}
			} catch (err) {
				// Message.error('未有权限，请联系管理员')
				router.replace({path: "/404"})
			}
		},
	},
	modules: {},
	getters: {
		// 检查菜单是否存在
		isNavItems: (state) => (item) => {
			for (const i of state.navItems) {
				if (i.moduleName === item) {
					return true;
				}
			}
			return false;
		},
	},
});
