import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'login',
		component: () => import('../views/login/index.vue')
	},
	{
		path: '/dataV',
		name: 'DataV',
		component: () => import('../views/dataV/index.vue')
	},
	{
		path: '/index',
		name: 'index',
		component: () => import('../views/index/index.vue')
	},
	{
		path: '/home',
		name: 'home',
		component: () => import('../views/home/index.vue')
	},
	{
		path: '/simpleness',
		name: 'simpleness',
		component: () => import('../views/simpleness/index.vue')
	},
	{
		path: '/electronic',
		name: 'electronic',
		component: () => import('../views/FacilityBack/electronic.vue')
	},
	{
		path: '/main',
		name: 'main',
		component: () => import('../views/main/index.vue'),
		redirect: '/',
		children: [
			{
				path: 'index',
				name: 'home',
				component: () => import('../views/index/index.vue'),
				redirect: '/index'
			},
			// 泵站管理
			{
				path: 'pumpStation/:id/:staId',
				name: 'pumpStation',
				component: () => import('../views/pumpStation/index.vue'),
				children: []
			},
			{
				// 站点监测
				path: 'StationManage/:siteId',
				name: 'StationManage',
				component: () => import('../views/StationManage/index.vue'),
				children: []
			},
			{
				// 水位监测
				path: 'WaterLevel/:siteId',
				name: 'WaterLevel',
				component: () => import('../views/WaterLevel/index.vue'),
				children: []
			},
			{
				// 雨情遥测
				path: 'RainCondition',
				name: 'RainCondition',
				component: () => import('../views/RainCondition/index.vue'),
				redirect: 'RainCondition/Rainfall',
				children: [
					{
						// 降雨量监测
						path: 'RainFall',
						name: 'RainFall',
						component: () =>
							import('../views/RainCondition/RainFall/index.vue')
					},
					{
						// 雨情日报表
						path: 'RainDay',
						name: 'RainDay',
						component: () => import('../views/RainCondition/RainDay/index.vue')
					},
					{
						// 雨情月报表
						path: 'RainMonth',
						name: 'RainMonth',
						component: () =>
							import('../views/RainCondition/RainMonth/index.vue')
					},
					{
						// 雨情年报表
						path: 'RainYear',
						name: 'RainYear',
						component: () =>
							import('../views/RainCondition/RainYear/index.vue')
					},
					{
						// 数据修正
						path: 'DataCheck',
						name: 'DataCheck',
						component: () =>
							import('../views/RainCondition/DataCheck/index.vue')
					}
				]
			},
			// 轨迹回放
			{
				path: '/gps',
				name: 'gps',
				component: () => import('../views/FacilityBack/gps.vue')
			},
			// {
			//   // 测流点管理
			//   path: 'UseWater/:siteId',
			//   name: 'UseWater',
			//   component: () => import('../views/UseWater/index.vue'),
			//   children: []
			// },
			{
				// 测流点管理
				path: 'flowMeter',
				name: 'flowMeter',
				component: () => import('../views/flowMeter/index.vue')
			},
			{
				// 测流点历史记录
				path: 'cldHistory',
				name: 'cldHistory',
				component: () => import('../views/cldHistory/index.vue')
			},
			{
				// 监控总览
				path: 'VideoAll/:siteId',
				name: 'VideoAll',
				component: () => import('../views/VideoAll/index.vue'),
				children: []
			},

			{
				// 地图
				path: 'viewmap',
				name: 'viewmap',
				component: () => import('../views/imgView/viewmap.vue'),
				children: []
			},
			{
				// 非法抓拍
				path: 'Capture',
				name: 'Capture',
				component: () => import('../views/Capture/index.vue')
			},
			{
				// 查询管理
				path: 'SearchManage',
				name: 'SearchManage',
				component: () => import('../views/SearchManage/index.vue'),
				redirect: 'SearchManage/FlowStatistics',
				children: [
					{
						// 泵站统计查询Pumping
						path: 'Pumping',
						name: 'Pumping',
						component: () =>
							import('../views/SearchManage/Pumping/index.vue')
					},
					{
						// 流量统计查询
						path: 'FlowStatistics',
						name: 'FlowStatistics',
						component: () =>
							import('../views/SearchManage/FlowStatistics/index.vue')
					},
					
					{
						// 闸门起闭统计
						path: 'GateOpenClose',
						name: 'GateOpenClose',
						component: () =>
							import('../views/SearchManage/GateOpenClose/index.vue')
					},
					{
						// 站点档案管理
						path: 'StationsManage',
						name: 'StationsManage',
						component: () =>
							import('../views/SearchManage/StationsManage/index.vue')
					},
					{
						// 视频设备管理
						path: 'VideoManage',
						name: 'VideoManage',
						component: () =>
							import('../views/SearchManage/VideoManage/index.vue')
					},
					{
						// 视频设备管理
						path: 'StorageDevice',
						name: 'StorageDevice',
						component: () =>
							import('../views/SearchManage/StorageDevice/index.vue')
					},
					{
						// 雨情设备管理
						path: 'RainMessage',
						name: 'RainMessage',
						component: () =>
							import('../views/SearchManage/RainMessage/index.vue')
					},
					{
						// 闸门档案管理
						path: 'GateManage',
						name: 'GateManage',
						component: () =>
							import('../views/SearchManage/GateManage/index.vue')
					},
					{
						// 门禁红外线
						path: 'infrared',
						name: 'infrared',
						component: () => import('../views/SearchManage/infrared/index.vue')
					}
				]
			},
			{
				// 操作日志
				path: 'history',
				name: 'history',
				component: () => import('../views/history/index.vue')
			},
			{
				// 报警记录
				path: 'AlarmLog',
				name: 'AlarmLog',
				component: () => import('../views/AlarmLog/index.vue')
			},
			{
				// 设备追回
				path: 'FacilityBack',
				name: 'FacilityBack',
				component: () => import('../views/FacilityBack/index.vue')
			},
			{
				// 权限管理
				path: 'AuthorManage',
				name: 'AuthorManage',
				component: () => import('../views/AuthorManage/index.vue'),
				redirect: 'AuthorManage/UserManage',
				children: [
					{
						path: 'UserManage',
						name: 'UserManage',
						component: () =>
							import('../views/AuthorManage/UserManage/index.vue')
					},
					{
						path: 'RoleManage',
						name: 'RoleManage',
						component: () =>
							import('../views/AuthorManage/RoleManage/index.vue')
					}
				]
			},
			// 设备管理
			{
				path: 'equipment',
				name: 'equipment',
				component: () => import('../views/Equipment/index.vue'),
				children: []
			},
		]
	},
	{
		path: '/404',
		name: 'notFound',
		component: () => import('../views/NotFound/NotFound.vue')
	},
	{
		path: '*', // 此处需特别注意置于最底部
		redirect: '/404'
	}
]

const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes
})

export default router
