import store from "../../store/index";
import { MessageBox } from 'element-ui'

class WS {
  constructor() {
    this.ws = null
    this.audio = document.createElement("audio")
    this.audio.src = "/10344.mp3"
    this.audio.loop = true
    this.audio.load()
  }
  create () {
    let url = ''
    switch (process.env.VUE_APP_CURENV) {
      case "development": //开发环境
        // url = "wss://test.zhsl.xuruidea.com/ws";
        // url = 'ws://192.168.0.106:9502/ws'  // 胡凯
        url = 'wss://test.yxzhsl.xuruidea.com/ws'
        break
      case "test": //测试环境
        url = "wss://test.yxzhsl.xuruidea.com/ws";
        break
      case "uat": //生产环境
        url = "wss://uat.yxzhsl.xuruidea.com/ws";
        break
      case "production": //生产环境
        url = "wss://yxzhsl.xuruidea.com/ws";
        break
    }
    this.ws = new WebSocket(url)
    this.ws.onerror = () => {
      if (this.ws) this.ws.close()
      else this.create()
    }
    this.ws.onclose = () => {
      this.create()
    }
    this.ws.onmessage = (e) => {
      try {
        const data = JSON.parse(e.data)
        if (data.entranceGuardDto) {
          /**
           * deviceType
           * 0 门禁
           * 1 红外线
           */

          /**
            * deviceName
            * 设备名称
            */
          const { deviceName, deviceType } = data.entranceGuardDto
          const content = `${deviceName}${deviceType === 0 ? '门禁被打开' : '红外线被遮挡'}`
          this.audio.currentTime = 0
          this.audio.play()
          MessageBox.confirm(content, "警告", {
            showClose: false,
            showCancelButton: false,
            closeOnPressEscape: false,
            closeOnClickModal: false,
            confirmButtonText: "确定",
            type: "warning",
          })
          .then(() => {
              this.audio.pause()
          })
        } else {
          store.commit('setWSData', data)
        }
      } catch {}
    }
  }
  readyState() {
    if (this.ws) {
      if (this.ws.readyState !== 1) {
        return false
      } else {
        return true
      }
    }
    return false
  }
}

const ws = new WS()
export default ws
